@import "bootstrap/scss/bootstrap";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

// aplication styles
div.journey-story {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    padding-bottom: 0;
    box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
    margin-top: -8px;
    margin-bottom: 0.9em;
    overflow: hidden;
    border-radius: 8px;
    will-change: transform;

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
        padding: 0;
        margin: 0;
    }
}

header>img.img-sigla {
    width: auto;
    height: 45px;
    margin-right: 10px;
}

.img-icon-32 {
    width: 32px;
    height: 32px;
}

.img-icon-24 {
    width: 24px;
    height: 24px;
}

.position-relative {
    position: relative;
}

.position-top-left {
    position: absolute;
    top: 2px;
    left: 6px;
}

a.img-remove-icon {
    @extend .bg-light;
    @extend .p-2;
    @extend .ms-2;
    @extend .rounded;
}

a {
    text-decoration: none;
}

// Custom bootstrap styles
input[aria-invalid]:invalid {
    @extend .border;
    @extend .border-danger;
  }
  
  input[aria-invalid]:invalid:focus ~ div.invalid-tooltip {
    position: static;
    @extend .d-block;
    @extend .w-100;
    @extend .opacity-75;
    @extend .py-2;
    p {
      @extend .p-0;
      @extend .m-0;
    }
  }
  
  textarea[aria-invalid]:required:invalid {
    @extend .border;
    @extend .border-danger;
  }
  
  textarea[aria-invalid]:invalid:focus ~ div.invalid-tooltip {
    position: static;
    @extend .d-block;
    @extend .w-100;
    @extend .opacity-75;
    @extend .py-2;
    p {
      @extend .p-0;
      @extend .m-0;
    }
  }
  
  select[aria-invalid]:required:invalid {
    @extend .border;
    @extend .border-danger;
  }
  
  select[aria-invalid]:invalid:focus ~ div.invalid-tooltip {
    position: static;
    @extend .d-block;
    @extend .w-100;
    @extend .opacity-75;
    @extend .py-2;
    p {
      @extend .p-0;
      @extend .m-0;
    }
  }  

input.validate:required:invalid {
    @extend .is-invalid;
}

input.validate:required:valid {
    @extend .is-valid;
}

textarea.validate:required:invalid {
    @extend .is-invalid;
}

textarea.validate:required:valid {
    @extend .is-valid;
}

.text-large {
    font-size: large;
}

.text-larger {
    font-size: larger;
}

nav.navbar ul {
    @extend .navbar-nav;
    @extend .mt-2;
    @extend .mt-lg-0;
    flex-direction: row;

    li {
        @extend .nav-item;
        @extend .mx-2;

        a {
            @extend .nav-link;
        }
    }
}

@media screen and (max-width: 992px) {
    nav.navbar div.navbar-collapse ul {
        flex-direction: column;
    }
    div.userway_buttons_wrapper{
        top: 20px !important;
        left: 50% !important;
        right: 50% !important;
    }
}

table:not([class]) {
    @extend .table;
    @extend .table-borderless;
}

table>thead>tr {
    @extend .border-bottom;
    font-size: smaller;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):not([type="range"]):not([type="color"]),
textarea {
    @extend .form-control;
}

input[type="checkbox"] {
    @extend .form-check-input;
}

input[type="radio"] {
    @extend .form-check-input;
}

select {
    @extend .form-select;
}

button:not([class]) {
    @extend .btn;
    @extend .btn-primary;
}

form>fieldset {
    @extend .mb-3;
    display: block;
}

form label {
    @extend .form-label;
    @extend .mt-3;
}

form label:not([class]) {
    @extend .text-muted;
}

form>label {
    display: block;
}

form>button:not(:last-child) {
    @extend .me-3;
}
div[role=search] {
    width: 100%;
    min-height: 400px;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    background-image: url('/images/sustain-background.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
}

form[role=search] {
    width: 600px;
    max-width: 90vw;
}

input[role=search] {
    height: 50px;
    padding: 20px !important;
    font-size: 1.5rem;
    // show search icon in input
    background-image: url('data:image/svg+xml;utf8,<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-4.35-4.35M11 5.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11z" /></svg>');
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 1.5rem;
    font-weight: bold !important;
    opacity: 0.8;
}

::-webkit-input-placeholder {
    font-style: italic;
}

:-moz-placeholder {
    font-style: italic;
}

::-moz-placeholder {
    font-style: italic;
}

:-ms-input-placeholder {
    font-style: italic;
}